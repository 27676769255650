import { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import fileDownload from 'js-file-download'

import BalanceStatementCsvModal from './BalanceStatementCsvModal'
import BalanceStatementPdfModal from './BalanceStatementPdfModal'

import { SelectAccount } from '../../components'
import { TransactionsTable } from './TransactionsTable'
import { TransactionsFilter } from './TransactionsFilter'
import { Button, ErrorDisplay, NoElementsMessage, Pagination, Spinner } from 'mmfintech-portal-commons'
import { DepositIcon, ExchangeIcon, PaymentLinkIcon, PayoutIcon, WithdrawIcon } from '../../icons'

import {
  ExportButton,
  TransactionsHeader,
  TransactionsWrapper,
  TransactionsFooterContainer,
  TransactionsPaginationContainer
} from './Transactions.styled'

import settings from '../../settings'
import { GlobalContext, isValidArray, tr } from 'mmfintech-commons'
import {
  actions,
  paths,
  useAppSelector,
  selectMerchantCapabilities,
  useTransactionsFilterQuery,
  endpoints,
  configuration,
  usePermissions,
  useMerchantAccounts,
  useFileDownloader
} from 'mmfintech-backend-api'

import { AccountBalanceOut, PortalUserPermissionsEnum } from 'mmfintech-commons-types'

import NoTransactionsIcon from '../../images/icons/no-transactions.svg?react'
import RecipientsIcon from '../../images/icons/recipients-icon.svg?react'

const Transactions = ({ match }) => {
  const accountId = parseInt(match.params['accountId'])

  const { modalShow } = useContext(GlobalContext)

  const capabilities = useAppSelector(selectMerchantCapabilities)

  const { accounts } = useMerchantAccounts()

  const { enablePortalWalletCheckout } = capabilities || {}

  const [selectedAccount, setSelectedAccount] = useState<AccountBalanceOut>()

  const history = useHistory()

  const filter = useTransactionsFilterQuery({
    accountId,
    cookieDomain: configuration.isLocal() ? null : settings.cookieDomain,
    defaultVisible: ['amount', 'currency', 'foreignId', 'reference', 'status'],
    isAutoReload: false
  })
  const { hasAccessTo, hasWritePermission, isOwnerOrAdministrator } = usePermissions()

  const downloader = useFileDownloader()

  const handleRecipientsClick = () => history.push(paths.recipients.list())

  const handleDepositClick = () => {
    actions.routing.setDepositOrigin('transactions')
    if (selectedAccount) {
      history.push({
        pathname: '/deposit',
        state: {
          preSelectedAccount: selectedAccount?.id
        }
      })
    } else {
      history.push(paths.banking.deposit())
    }
  }

  const handleWithdrawClick = () => {
    actions.routing.setWithdrawOrigin('transactions')
    history.push(paths.withdraw.select())
  }

  const handlePayoutClick = () => {
    actions.routing.setWithdrawOrigin('transactions')
    history.push(paths.payout.select())
  }

  const handleExportStatementPdf = () => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false, closeOnEscape: false },
      content: (
        <BalanceStatementPdfModal
          account={selectedAccount}
          accounts={accounts}
          dateFrom={filter.values.get('from')}
          dateTo={filter.values.get('to')}
        />
      )
    })
  }

  const handleExportStatementCsv = () => {
    modalShow({
      options: { size: 'auto', closeOnClickOutside: false, closeOnEscape: false },
      content: (
        <BalanceStatementCsvModal
          account={selectedAccount}
          accounts={accounts}
          dateFrom={filter.values.get('from')}
          dateTo={filter.values.get('to')}
        />
      )
    })
  }

  const handleExportClick = async () => {
    if (!downloader.fetching) {
      void downloader.download({
        url: endpoints.transactions.getReportCSV(),
        method: 'POST',
        data: filter.prepareFilter(null),
        onSuccess: (data: any, filename) => {
          fileDownload(data, filename || 'transactions.csv', 'text/csv')
        }
      })
    }
  }

  const handleSelectAccount = (account: AccountBalanceOut) => {
    if (account == null || typeof account === 'undefined') {
      setSelectedAccount(null)
      filter.values.set('accountId', null)
      filter.values.reload()
    } else if (account.id !== selectedAccount?.id) {
      setSelectedAccount(account)
      filter.values.set('accountId', account.id)
      filter.values.reload()
    }
  }

  useEffect(() => {
    if (isValidArray(accounts) && !selectedAccount && accountId) {
      setSelectedAccount(accounts.find(account => account.id === accountId))
    }
    // eslint-disable-next-line
  }, [accounts])

  return (
    <TransactionsWrapper>
      <TransactionsHeader>
        <div className='left-side'>
          <SelectAccount
            showAllAccounts
            accounts={accounts}
            label={tr('FRONTEND.TRANSACTIONS.ACCOUNT_LABEL', 'Account')}
            selectedAccount={selectedAccount}
            setSelectedAccount={handleSelectAccount}
          />
          <Button
            color='alternative'
            icon={<RecipientsIcon />}
            text={tr('FRONTEND.TRANSACTIONS.RECIPIENTS_BUTTON', 'Recipients')}
            onClick={handleRecipientsClick}
            data-test='button-recipients'
          />
        </div>

        {isOwnerOrAdministrator() || hasWritePermission() || enablePortalWalletCheckout ? (
          <div className='right-side'>
            {hasWritePermission() && (
              <>
                <Button
                  color='alternative'
                  icon={<DepositIcon />}
                  text={tr('FRONTEND.DASHBOARD.BUTTON_DEPOSIT', 'Deposit')}
                  onClick={handleDepositClick}
                  data-test='button-deposit'
                />
                {hasAccessTo(PortalUserPermissionsEnum.WITHDRAWALS) && (
                  <Button
                    color='alternative'
                    icon={<WithdrawIcon />}
                    text={tr('FRONTEND.DASHBOARD.BUTTON_WITHDRAW', 'Withdraw')}
                    onClick={handleWithdrawClick}
                    data-test='button-withdraw'
                  />
                )}
                <Button
                  color='alternative'
                  icon={<ExchangeIcon />}
                  text={tr('FRONTEND.DASHBOARD.BUTTON_EXCHANGE', 'Exchange')}
                  onClick={() => history.push(paths.banking.exchange())}
                  data-test='button-exchange-link'
                />
                {enablePortalWalletCheckout && (
                  <Button
                    color='alternative'
                    icon={<PaymentLinkIcon />}
                    text={tr('FRONTEND.DASHBOARD.BUTTON_PAYMENT_LINK', 'Payment link')}
                    onClick={() => history.push(paths.scanAndPay())}
                    data-test='button-payment-link'
                  />
                )}
                {hasAccessTo(PortalUserPermissionsEnum.PAYOUTS) && (
                  <Button
                    color='alternative'
                    icon={<PayoutIcon />}
                    text={tr('FRONTEND.DASHBOARD.BUTTON_PAYOUT', 'Payout')}
                    onClick={handlePayoutClick}
                    data-test='button-payout'
                  />
                )}
              </>
            )}
          </div>
        ) : null}
      </TransactionsHeader>

      <TransactionsFilter filter={filter} />
      <ErrorDisplay error={downloader.error} />

      {filter.transactionsFetching ? (
        <Spinner />
      ) : filter.transactionsError ? (
        <ErrorDisplay error={filter.transactionsError} />
      ) : isValidArray(filter.transactions) ? (
        <>
          <TransactionsPaginationContainer>
            <TransactionsTable transactions={filter.transactions} reload={() => filter.pagination.reload()} />
            <Pagination className='transactions-pagination' {...filter.pagination.register()} />
          </TransactionsPaginationContainer>
          <TransactionsFooterContainer>
            <ExportButton onClick={handleExportStatementPdf} data-test='button-export-statement-pdf'>
              <span className='icon export-pdf' />
              {tr('FRONTEND.TRANSACTIONS.STATEMENT_PDF', 'Balance statement')}
            </ExportButton>
            <ExportButton onClick={handleExportStatementCsv} data-test='button-export-statement-csv'>
              <span className='icon export-csv' />
              {tr('FRONTEND.TRANSACTIONS.STATEMENT_CSV', 'Statement CSV')}
            </ExportButton>
            <ExportButton onClick={handleExportClick} data-test='button-export-csv'>
              <span className='icon' />
              {tr('FRONTEND.TRANSACTIONS.EXPORT_CSV', 'Transactions CSV')}
            </ExportButton>
          </TransactionsFooterContainer>
        </>
      ) : (
        <NoElementsMessage
          icon={<NoTransactionsIcon />}
          message={tr(
            'FRONTEND.BANKING.TRANSACTIONS.NO_TRANSACTIONS',
            'No transactions for the selected period or search criteria'
          )}
        />
      )}
    </TransactionsWrapper>
  )
}

export default Transactions
